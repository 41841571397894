import React from "react";

const Footer = () => {
  return (
    <footer className="w-full py-4 bottom-0 border-t border-[#112483]">
      <div className="container mx-auto text-center ">
        <p className="font-bold font-molo text-sm text-[#112483]">
          <span>© 2024 Dragon Ball Z </span>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
