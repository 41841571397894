import React, { useState, useRef } from "react";
import IMG_0335 from "../assets/images/IMG_0335.png";
import modelsheet from "../assets/images/modelsheet.png";

const KamehamehaEffectOverlay = () => {
  const [isCharging, setIsCharging] = useState(false);
  const audioRef = useRef(null);

  const handleKamehameha = () => {
    if (!audioRef.current) {
      audioRef.current = new Audio("/kamehameha.mp3");
    }
    setIsCharging(true);
    setIsModalOpen(true);
    audioRef.current.play();
    setTimeout(() => {
      setIsCharging(false);
    }, 6000);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
    setIsCharging(false);
    if (audioRef.current) {
      audioRef.current.pause(); // Hentikan audio
      audioRef.current.currentTime = 0; // Reset waktu audio ke awal
    }
  };

  return (
    <div className="relative lg:py-10 lg:px-16 px-2 py-4 flex flex-col lg:flex-row items-center justify-center">
      {/* Iframe dengan Dex Screener */}
      <div
        className="relative w-[90%] pb-[100%] md:w-[95%] md:pb-[55%] lg:w-[70%] lg:pb-[55%] order-2 lg:order-1 mt-8 lg:mt-0"
        id="satu"
      >
        <iframe
          src="https://raydium.io/swap/?inputMint=EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v&outputMint=sol"
          className="absolute top-0 left-0 bottom-0 w-full h-full border-0 rounded-2xl"
          title="Dex Screener"
          aria-label="Dex Screener"
        ></iframe>
      </div>

      {/* Container for image and effects */}
      <div className="w-full lg:w-1/2 h-auto px-6 order-1 lg:order-2" id="dua">
        <div className="relative overflow-hidden">
          {/* Base character image */}
          <img
            src={IMG_0335}
            alt="Character"
            className="w-full h-full object-contain relative z-10"
            style={{ cursor: isCharging ? "not-allowed" : "pointer" }}
          />

          {/* Energy gathering effects - now contained within #gambar */}
          {isCharging && (
            <div className="absolute inset-0 z-0">
              {/* Aura effect */}
              <div className="absolute inset-0 animate-pulse">
                <div className="absolute inset-0 bg-blue-500/20 rounded-full blur-xl transform scale-110" />
                <div className="absolute inset-0 bg-blue-400/20 rounded-full blur-lg transform scale-105" />
              </div>

              {/* Energy particles */}
              <div className="absolute inset-0 overflow-hidden">
                {[...Array(20)].map((_, i) => (
                  <div
                    key={i}
                    className="absolute w-2 h-2 bg-blue-400 rounded-full animate-ping"
                    style={{
                      left: `${Math.random() * 100}%`,
                      top: `${Math.random() * 100}%`,
                      animationDelay: `${Math.random() * 1}s`,
                      animationDuration: `${1 + Math.random() * 2}s`,
                    }}
                  />
                ))}
              </div>

              {/* Additional power-up effects */}
              <div className="absolute inset-0">
                <div
                  className="absolute inset-0 bg-blue-300/30 rounded-full blur-md animate-pulse"
                  style={{ animationDuration: "0.8s" }}
                />
                <div className="absolute inset-0 border-2 border-blue-400/50 rounded-full animate-[ping_1.5s_ease-out_infinite]" />
              </div>
            </div>
          )}

          {/* Button placed within image */}
          <button
            className="absolute bottom-10 left-32 md:bottom-32 md:left-80 lg:bottom-16 lg:left-40 text-lg transform -translate-x-1/2 bg-[#112483] text-white w-20 h-20 md:w-44 md:h-44 lg:w-20 lg:h-20 rounded-full 
    hover:bg-[#F94721] shadow-xl transition-transform duration-300 ease-in-out hover:scale-110 hover:shadow-2xl active:scale-95 active:shadow-lg z-20 font-londrina"
            onClick={handleKamehameha}
          >
            Click Me
          </button>
        </div>

        <div>
          <p className="text-justify">
            Geku stands as a remarkable fusion of Saiyan might and Gecko
            agility. Born with a pure heart like Goku, yet blessed with a
            Gecko's adaptive prowess, Geku possesses the unique ability to shed
            both skin and battle tactics at will. This extraordinary warrior can
            scale impossible heights and vanish at crucial moments, while
            maintaining the innocent spirit that sees good in all beings.
          </p>
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="relative bg-white p-6 rounded-lg shadow-lg w-full md:w-1/3">
            {/* Tombol Close */}
            <button
              className="absolute top-0 right-0 bg-red-500 text-white font-bold px-3 py-1 rounded-bl-lg rounded-tr-lg hover:bg-red-700 transition"
              onClick={closeModal}
            >
              ✕
            </button>

            <h2 className="text-4xl font-molot  mb-2 py-2 border-b border-gray-700">
              Model Sheet
            </h2>
            <img src={modelsheet} alt="Modal_Sheet_Image" />
          </div>
        </div>
      )}

      {/* Custom keyframes for energy ring animation */}
      <style jsx>{`
        @keyframes moveRight {
          from {
            transform: translateX(-100%);
          }
          to {
            transform: translateX(100%);
          }
        }
      `}</style>
    </div>
  );
};

export default KamehamehaEffectOverlay;
